import React from 'react';
import { CustomGridColumns } from '../../CustomDataGrid';

export const tributacaoEntradaColumns: CustomGridColumns[] = [
  {
    field: 'val_icms_ent_nf',
    headerName: 'Aliq. ICMS Entrada',
    width: 130,
    format: 'Percentage',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'tipo_tributacao_ent_nf',
    headerName: 'Tributação Entrada',
    options: [
      { value: 0, label: 'Tributado' },
      { value: 1, label: 'Isento' },
      { value: 2, label: 'Redução' },
      { value: 3, label: 'Substituição' },
      { value: 4, label: 'Suspensão' },
      { value: 5, label: 'Diferido' },
      { value: 6, label: 'Outros ICMS' },
      { value: 7, label: 'Portaria CAT-38' },
      { value: 8, label: 'Não Tributado' },
    ],
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_red_bc_ent_nf',
    headerName: 'Red. BC Entrada',
    width: 150,
    format: 'Percentage',
    sortable: false,
    disableColumnMenu: true,
  },
];
