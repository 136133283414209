import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0;

  .table tbody {
    border: 1px solid #daebf6 !important;
  }
  .table tbody tr td {
    color: #757575;
    font-size: 14px;
    font-weight: bold;
    background: #daebf6;
    border-left: 1px solid #daebf6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #8850bf;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const Button = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;
  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: #eda934 !important;
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  .MuiTableCell-stickyHeader {
    background-color: ${({ theme: { colors } }) => colors.nav};
  }
`;

export const ScrollButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
  gap: 0.25rem;
  flex-wrap: wrap;

  button {
    background-color: #57069e;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-family: Jost, sans-serif;
    &:active,
    &:focus,
    &:hover {
      background-color: #8850bf;
      outline: none;
      box-shadow: none;
    }
  }
`;

export const ButtonRow = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;
