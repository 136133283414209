import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

interface StyleProps {
  background?: string;
  columnWidth?: number;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CustomTableCell = styled(TableCell)<StyleProps>`
  position: sticky;
  right: 0;
  width: ${(props) => props.columnWidth};
  min-width: ${(props) => props.columnWidth};
  background: ${(props) => (props.background ? props.background : '#ffffff')};
  z-index: 2;

  button {
    color: #48778c;
    &:hover {
      background: ${(props) =>
        props.background ? props.background : '#ffffff'} !important;
      opacity: 0.8;
    }
  }
`;

export const ColumnSeparator = styled.div`
  color: rgba(224, 224, 224, 1);
  right: -12px;
  display: flex;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;
  opacity: 1;
  top: 0;

  .MuiSvgIcon-root {
    width: 1.1em;
  }
`;
