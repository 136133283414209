import React, { ReactElement, useCallback, useEffect } from 'react';
import { InputMoney } from '~/components/NovosInputs/InputMoney2';
import useDebounce from '~/hooks/useDebounce';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import { moneyFormat, transformAsCurrency } from '~/utils/functions';
import { Produto } from '../../../../../../protocols';

const CustomInputMoney = ({
  row,
  fieldName,
  campo,
}: {
  row: any;
  fieldName: string;
  campo:
    | 'val_tabela'
    | 'val_tot_despesa_acessoria'
    | 'val_acrescimo'
    | 'val_indenizacao'
    | 'val_tot_frete'
    | 'val_tot_desconto';
}): ReactElement<any, any> => {
  const {
    produtos,
    setProdutos,
    formTabelaItens,
    recalculaTotais,
    resetItens,
  } = useDevolucaoSaidaNFEForn();
  const { debouncedFn } = useDebounce();

  const {
    setValueTabelaItens,
    registerTabelaItens,
    controlTabelaItens,
    setErrorTabelaItens,
  } = formTabelaItens;

  const updateValorTotal = useCallback(
    (prod: Produto, value: any, cod_seq_nf_item: number) => {
      prod.val_tabela = transformAsCurrency(value.value);
      prod.val_produto = transformAsCurrency(value.value);
      const val_total = Number(prod.qtd_total) * Number(prod.val_tabela);
      prod.val_total = transformAsCurrency(val_total);
      setProdutos((prevProdutos) => {
        return prevProdutos.map((p) => {
          if (p.cod_seq_nf_item === cod_seq_nf_item) {
            return {
              ...p,
              val_tabela: prod.val_tabela,
              val_produto: prod.val_produto,
              val_total: prod.val_total,
            };
          }
          return p;
        });
      });
    },
    [setProdutos],
  );
  const updateDespAcessoria = useCallback(
    (prod: Produto, value: any, cod_seq_nf_item: number) => {
      prod.val_tot_despesa_acessoria = transformAsCurrency(value.value);
      setProdutos((prevProdutos) => {
        return prevProdutos.map((p) => {
          if (p.cod_seq_nf_item === cod_seq_nf_item) {
            return {
              ...p,
              val_tot_despesa_acessoria: transformAsCurrency(value.value),
            };
          }
          return p;
        });
      });
    },
    [setProdutos],
  );
  const updateValorAcrescimo = useCallback(
    (prod: Produto, value: any, cod_seq_nf_item: number) => {
      prod.val_acrescimo = transformAsCurrency(value.value);
      setProdutos((prevProdutos) => {
        return prevProdutos.map((p) => {
          if (p.cod_seq_nf_item === cod_seq_nf_item) {
            return {
              ...p,
              val_acrescimo: transformAsCurrency(value.value),
            };
          }
          return p;
        });
      });
    },
    [setProdutos],
  );
  const updateValorIndenizacao = useCallback(
    (prod: Produto, value: any, cod_seq_nf_item: number) => {
      prod.val_indenizacao = transformAsCurrency(value.value);
      setProdutos((prevProdutos) => {
        return prevProdutos.map((p) => {
          if (p.cod_seq_nf_item === cod_seq_nf_item) {
            return {
              ...p,
              val_indenizacao: transformAsCurrency(value.value),
            };
          }
          return p;
        });
      });
    },
    [setProdutos],
  );
  const updateValorFrete = useCallback(
    (prod: Produto, value: any, cod_seq_nf_item: number) => {
      prod.val_tot_frete = transformAsCurrency(value.value);
      setProdutos((prevProdutos) => {
        return prevProdutos.map((p) => {
          if (p.cod_seq_nf_item === cod_seq_nf_item) {
            return {
              ...p,
              val_tot_frete: transformAsCurrency(value.value),
            };
          }
          return p;
        });
      });
    },
    [setProdutos],
  );
  const updateValorDesconto = useCallback(
    (prod: Produto, value: any, cod_seq_nf_item: number) => {
      prod.val_tot_desconto = transformAsCurrency(value.value);
      setProdutos((prevProdutos) => {
        return prevProdutos.map((p) => {
          if (p.cod_seq_nf_item === cod_seq_nf_item) {
            return {
              ...p,
              val_tot_desconto: transformAsCurrency(value.value),
            };
          }
          return p;
        });
      });
    },
    [setProdutos],
  );

  const updateFields = useCallback(
    (value: any, cod_seq_nf_item: number) => {
      const prod = produtos.find(
        (item) => item.cod_seq_nf_item === cod_seq_nf_item,
      );

      if (prod) {
        switch (campo) {
          case 'val_tabela':
            updateValorTotal(prod, value, cod_seq_nf_item);
            break;
          case 'val_tot_despesa_acessoria':
            updateDespAcessoria(prod, value, cod_seq_nf_item);
            break;
          case 'val_acrescimo':
            updateValorAcrescimo(prod, value, cod_seq_nf_item);
            break;
          case 'val_indenizacao':
            updateValorIndenizacao(prod, value, cod_seq_nf_item);
            break;
          case 'val_tot_frete':
            updateValorFrete(prod, value, cod_seq_nf_item);
            break;
          case 'val_tot_desconto':
            updateValorDesconto(prod, value, cod_seq_nf_item);
            break;
          default:
            break;
        }
      }

      recalculaTotais();
    },
    [
      campo,
      produtos,
      recalculaTotais,
      updateDespAcessoria,
      updateValorAcrescimo,
      updateValorDesconto,
      updateValorFrete,
      updateValorIndenizacao,
      updateValorTotal,
    ],
  );

  useEffect(() => {
    if (resetItens) {
      const produto = produtos.find(
        (item) => item.cod_seq_nf_item === row.cod_seq_nf_item,
      );
      if (produto) {
        setValueTabelaItens(
          fieldName,
          moneyFormat(String(produto[campo] || 0), 2),
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetItens]);

  return (
    <>
      <InputMoney
        label=""
        placeholder=""
        min={0}
        decimals={2}
        name={fieldName}
        register={registerTabelaItens}
        control={controlTabelaItens}
        showIcon={false}
        maxLength={9}
        max={999999999}
        isError={!!setErrorTabelaItens[fieldName]}
        style={{ marginTop: -16, marginBottom: -8 }}
        onKeyUp={(value) => {
          debouncedFn(
            () => updateFields(value.target, row.cod_seq_nf_item),
            1000,
          );
        }}
      />
    </>
  );
};

export default CustomInputMoney;
