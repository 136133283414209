/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { moneyFormat } from '~/utils/functions';
import { CustomGridColumns } from '../../CustomDataGrid';
import CustomInputMoney from './components/CustomInputMoney';

export const variaveisColumns: CustomGridColumns[] = [
  {
    field: 'val_tot_despesa_acessoria',
    headerName: 'Tot. Desp. Acessória',
    width: 170,
    format: 'number2',
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const fieldName = `val_tot_despesa_acessoria_produto-${row.cod_seq_nf_item}`;
      if (row.isupdate) {
        return (
          <>{moneyFormat(String(row.val_tot_despesa_acessoria || 0), 2)}</>
        );
      }
      return (
        <CustomInputMoney
          row={row}
          fieldName={fieldName}
          campo="val_tot_despesa_acessoria"
        />
      );
    },
  },
  {
    field: 'val_acrescimo',
    headerName: 'Acréscimos',
    width: 170,
    format: 'number2',
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const fieldName = `val_acrescimo_produto-${row.cod_seq_nf_item}`;
      if (row.isupdate) {
        return <>{moneyFormat(String(row.val_acrescimo || 0), 2)}</>;
      }
      return (
        <CustomInputMoney
          row={row}
          fieldName={fieldName}
          campo="val_acrescimo"
        />
      );
    },
  },
  {
    field: 'val_indenizacao',
    headerName: 'Indenização',
    width: 170,
    format: 'number2',
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const fieldName = `val_indenizacao_produto-${row.cod_seq_nf_item}`;
      if (row.isupdate) {
        return <>{moneyFormat(String(row.val_indenizacao || 0), 2)}</>;
      }
      return (
        <CustomInputMoney
          row={row}
          fieldName={fieldName}
          campo="val_indenizacao"
        />
      );
    },
  },
  {
    field: 'val_tot_frete',
    headerName: 'Frete',
    width: 170,
    format: 'number2',
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const fieldName = `val_tot_frete_produto-${row.cod_seq_nf_item}`;
      if (row.isupdate) {
        return <>{moneyFormat(String(row.val_tot_frete || 0), 2)}</>;
      }
      return (
        <CustomInputMoney
          row={row}
          fieldName={fieldName}
          campo="val_tot_frete"
        />
      );
    },
  },
  {
    field: 'val_tot_desconto',
    headerName: 'Tot. Desconto',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const fieldName = `val_tot_desconto_produto-${row.cod_seq_nf_item}`;
      if (row.isupdate) {
        return <>{moneyFormat(String(row.val_tot_desconto || 0), 2)}</>;
      }
      return (
        <CustomInputMoney
          row={row}
          fieldName={fieldName}
          campo="val_tot_desconto"
        />
      );
    },
  },
  {
    field: 'val_tot_frete',
    headerName: 'Tot. Frete',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_frete_dest',
    headerName: 'Tot. Frete (CF)',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_tabela_liq',
    headerName: 'Tot. Liquido',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_tot_ipi',
    headerName: 'Tot. IPI',
    width: 100,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_tabela_final',
    headerName: 'Tot. Tabela Final',
    width: 100,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
];
