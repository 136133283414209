import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Loja from '~/components/Loja';
import {
  BuscaParceiro,
  InputAsyncSelect,
  InputDate,
  InputMoney,
  InputNumber,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import api from '~/services/api';
import { CardReferecia, ContainerReferecia } from './styles';
import { toast } from 'react-toastify';
import { transformAsCurrency } from '~/utils/functions';
import { SelectType } from '~/pages/DevolucaoESaidaNFDeFornecedor/protocols';

export const Capa: React.FC = () => {
  const {
    codLoja,
    setCodLoja,
    loja,
    changeLoja,
    register,
    getValues,
    formState,
    control,
    setValue,
    watch,
    clearErrors,
    nfsReferenciadas,
    setParametrosLoja,
    produtos,
    isUpdate,
    setPerfil,
  } = useDevolucaoSaidaNFEForn();

  const [cod_pessoa] = watch(['cod_pessoa']);
  const perfilEmissao = watch('cod_perfil');
  const [perfis, setPerfis] = useState<any[]>([]);
  const [flgInterestadual, setFlgInterestadual] = useState<boolean | undefined>(
    undefined,
  );

  const getParametrosLoja = useCallback(
    async (codigo: number) => {
      const res = await api.get(`regraloja/${codigo}`);
      const { data, success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }

      setParametrosLoja(data);
    },
    [setParametrosLoja],
  );

  useEffect(() => {
    if (!!loja?.uf && !!cod_pessoa?.des_uf) {
      setFlgInterestadual(loja?.uf !== cod_pessoa?.des_uf);
    }
    setValue('cod_especie', {
      value: 33,
      label: 'NFE - NOTA FISCAL ELETRÔNICA',
      des_especie: 'NFE',
      des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
      cod_doc_fiscal: '55',
      num_modelo_fiscal: '',
    });
  }, [getValues, loja?.uf, cod_pessoa, setValue]);

  const getPerfis = useCallback(async () => {
    const res = await api.get(`/perfil-nf-devolucao/${codLoja}`, {
      params: {
        flg_interestadual: flgInterestadual || false,
        flg_fornecedor: true,
      },
    });
    const { data } = res.data;

    const regs = data.map((item: any) => {
      const {
        descricao,
        des_tipo_nf,
        des_tipo_unidade,
        des_tipo_emissao,
        flg_gera_financeiro,
      } = item;

      return {
        value: item.cod_perfil,
        label: `${descricao} - Tipo: ${des_tipo_nf} | Un Emb: ${des_tipo_unidade} | Emiss: ${des_tipo_emissao} | Gera Fin: ${
          flg_gera_financeiro ? 'Sim' : 'Não'
        }`,
        cod_categoria: item.cod_categoria,
        cod_cc: item.cod_cc,
        cod_condicao: item.cod_condicao,
        cod_finalizadora: item.cod_finalizadora,
        cod_perfil: item.cod_perfil,
        des_perfil: item.des_perfil,
        descricao: item.descricao,
        num_condicao: item.num_condicao,
        per_desconto: item.per_desconto,
        tipo_emissao: item.tipo_emissao,
        tipo_nf: item.tipo_nf,
        tipo_operacao: item.tipo_operacao,
        tipo_unidade: item.tipo_unidade,
        flg_gera_financeiro: item.flg_gera_financeiro,
      };
    });
    setPerfis(regs);
  }, [codLoja, flgInterestadual]);

  useEffect(() => {
    getPerfis();
  }, [codLoja, flgInterestadual, getPerfis]);

  return (
    <>
      <Separator labelText="Capa da Nota" sidePadding="0 0" />
      <Row>
        <Col
          md={6}
          sm={12}
          style={{
            marginTop: '10px',
          }}
        >
          <Loja
            selectedLoja={codLoja}
            onChangedObject={(e) => {
              changeLoja(e);
            }}
            isDisabled={produtos.length > 0}
            onChange={(e) => {
              clearErrors('loja');
              setValue('loja', e);
              getParametrosLoja(Number(e));
              setCodLoja(Number(e));
              if (!isUpdate && codLoja !== e) setValue('cod_perfil', '');
            }}
            IsInvalid={!!formState.errors.loja}
            disabled={produtos.length > 0}
          />
        </Col>
        <Col md={6} sm={12}>
          <BuscaParceiro
            label="Fornecedor"
            placeholder="Selecione..."
            name="cod_pessoa"
            register={register}
            isError={!!formState.errors.cod_pessoa}
            control={control}
            customOptions={{
              buscarPor: { fornecedor: true },
              fields: ['cod_fornecedor'],
            }}
            changeSelected={(selected: any) => {
              clearErrors('cod_pessoa');
              if (!isUpdate) setValue('cod_perfil', '');
              if (selected?.cod_pessoa === undefined) return;
              const { des_uf }: any = selected;
              if (!des_uf) {
                toast.warning(
                  `Endereço incompleto, atualize o cadastro do parceiro para continuar.`,
                );
              }
              setValue('cod_pessoa', selected);
            }}
            disabled={isUpdate || produtos.length > 0}
          />
        </Col>
        <Col md={4} sm={12}>
          <InputSelect
            label="Perfil"
            id="DropDownWidth"
            ListaWidth="650px"
            placeholder="Selecione..."
            name="cod_perfil"
            autoComplete="off"
            register={register}
            isError={!!formState.errors.cod_perfil}
            control={control}
            disabled={produtos.length > 0}
            options={perfis}
            changeSelected={(selected: any) => {
              if (
                selected.flg_gera_financeiro &&
                (selected.cod_categoria === null || selected.cod_cc === null)
              ) {
                toast.warn(
                  `Dados financeiros do perfil '${selected.cod_perfil} - ${selected.des_perfil}' precisam ser corretamente preenchidos. Vá até ao cadastro de perfil, e complete seu cadastro.`,
                );
                setValue('cod_perfil', '');
                setPerfil({} as SelectType);
                return;
              }
              clearErrors('cod_perfil');
              clearErrors('cod_serie');
              setValue('cod_perfil', selected);
              setPerfil(selected);
            }}
          />
        </Col>
        <Col md={1} sm={12}>
          {!perfilEmissao || perfilEmissao?.tipo_emissao === 1 ? (
            <InputAsyncSelect
              label="Série"
              maxLength={50}
              placeholder="Série"
              name="cod_serie"
              register={register}
              isError={!!formState.errors.cod_serie}
              typeError={
                String(formState.errors.cod_serie?.message) || undefined
              }
              control={control}
              disabled={isUpdate}
              title={!loja?.cod_loja ? 'É necessário selecionar uma loja' : ''}
              changeSelected={(selected: any) => {
                clearErrors('cod_serie');
                setValue('cod_serie', selected);
              }}
              api={{
                route: `/serie-nf-devolucao/loja/${codLoja}`,
                method: 'get',
                fields: ['num_serie'],
                searchBeforeFilter: true,
              }}
            />
          ) : (
            <InputText
              label="Série"
              name="cod_serie"
              placeholder="Informe a série"
              register={register}
              isError={!!formState.errors.cod_serie}
              disabled={isUpdate}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.code === 'Space') {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                const pastedText = e.clipboardData.getData('text');
                const modifiedText = pastedText.replace(/\s+/g, '');
                const inputElement = e.target as HTMLInputElement;
                inputElement.value = modifiedText;
              }}
              maxLength={8}
            />
          )}
        </Col>
        <Col md={1} sm={12}>
          <InputSelect
            label="Espécie"
            maxLength={50}
            placeholder="Espécie"
            name="cod_especie"
            register={register}
            isError={!!formState.errors.cod_especie}
            control={control}
            options={[{ value: 33, label: 'NFE - NOTA FISCAL ELETRÔNICA' }]}
            disabled
            changeSelected={(selected) => {
              setValue('cod_especie', selected);
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber
            label="Nº NF"
            max={10000}
            maxLength={50}
            name="num_nf"
            title=""
            register={register}
            disabled
            isError={!!formState.errors.num_nf}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            register={register}
            label="Emissão"
            name="dta_emissao"
            disabled={isUpdate}
            control={control}
            maxDate={new Date().toISOString().slice(0, 10)}
            isError={!!formState.errors.dta_emissao}
            typeError={formState.errors.dta_emissao?.message}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            label="Saída"
            register={register}
            name="dta_entrada"
            disabled={isUpdate}
            control={control}
            minDate={new Date().toISOString().slice(0, 10)}
            isError={!!formState.errors.dta_entrada}
            typeError={formState.errors.dta_entrada?.message}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="Total NF"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_total_nf"
            disabled={isUpdate}
            register={register}
            isError={!!formState.errors.val_total_nf}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="BC ICMS OP"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_bc"
            register={register}
            isError={!!formState.errors.val_total_bc}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="ICMS OP"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_icms"
            register={register}
            isError={!!formState.errors.val_total_icms}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="BC ICMS ST"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_bc_st"
            register={register}
            isError={!!formState.errors.val_total_bc_st}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="ICMS ST"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_icms_st"
            register={register}
            isError={!!formState.errors.val_total_icms_st}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="FCP ST"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_st_fcp"
            register={register}
            isError={!!formState.errors.val_total_st_fcp}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="Venda Varejo"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_venda_varejo"
            register={register}
            isError={!!formState.errors.val_total_venda_varejo}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="Total IPI"
            placeholder="0,00"
            min={0}
            decimals={2}
            disabled={isUpdate}
            name="val_total_ipi"
            register={register}
            isError={!!formState.errors.val_total_ipi}
            showIcon
          />
        </Col>
      </Row>
      {nfsReferenciadas.length > 0 && (
        <>
          <Separator labelText="NF(s) Referenciada(s)" sidePadding="0 0" />
          <Row>
            <Col sm={12}>
              <ContainerReferecia>
                {nfsReferenciadas.map((nf) => {
                  return (
                    <CardReferecia key={nf.cod_seq_nf}>
                      {nf.num_nf}
                    </CardReferecia>
                  );
                })}
              </ContainerReferecia>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
