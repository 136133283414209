import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const BootstrapModal = styled(Modal)`
  .modal-header {
    height: 100px;
    padding: 0px 60px;
    background: #fff;
    color: #58079e;
    border-bottom: transparent;

    .modal-title {
      margin-top: auto;
      width: 100%;
      border-bottom: 1px solid #dee2e6;
    }
  }
  .modal-body {
    margin-top: 20px;
    padding-left: 80px;
    padding-right: 80px;
    .botoes-de-filtro {
      button {
        padding: 0 10px;
        height: 24px;
        background: #c6c6c6;
        border: none;

        cursor: pointer;
        & + button {
          margin-left: 2px;
        }
      }
    }
    .aba-ativa {
      background: ${(props) => props.theme.colors.nav} !important;
      color: #fff;
    }
    .modal-acoes {
      height: 70px;
      transition: 0.3s;
      cursor: pointer;

      & + .modal-acoes {
        border-top: 1px solid #ededed;
      }
      & + a {
        border-top: 1px solid #ededed !important;
      }
      h5 {
        font-size: 18px;
        color: #474747;
      }
      small {
        font-size: 13px;
      }
      svg {
        color: ${(props) => props.theme.colors.nav} !important;
      }
    }
    .executando {
      background: #ebebeb;
      transition: 0.3s;

      cursor: default;
      h5 {
        color: #a19d9d !important;
      }
      small {
        color: #a19d9d !important;
      }
      svg {
        color: #9d7dbd !important;
      }
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .nav-tabs {
      .nav-item {
        & + .nav-item {
          display: none;
          visibility: hidden;
        }
      }
    }
    .button-confirm {
      margin-top: 31px;
    }
  }
  .modal-footer {
    button {
      background: ${(props) => props.theme.colors.nav};
      border-color: ${(props) => props.theme.colors.nav};

      &:focus:not(.focus-visible) {
        outline: none !important;
        outline: 0px !important;
        -webkit-appearance: none;
        box-shadow: none !important;
      }
      &:focus-visible {
        outline: 2px solid ${(props) => props.theme.colors.nav} !important;
      }
    }
  }

  .input-qtd {
    outline: none;
    box-sizing: border-box;
    margin: 0;
    display: block;
    width: 100px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    background-color: #fff;
    padding: 8px 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 14px;
    text-align: right;
  }

  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    color: #757575;
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #8850bf;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
