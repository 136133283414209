/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { CustomGridColumns } from '../../CustomDataGrid';

export const vendaVarejoColumns: CustomGridColumns[] = [
  {
    field: 'val_venda_varejo',
    headerName: 'Tot. Venda Varejo',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
];
