import React from 'react';
import { CustomGridColumns } from '../../CustomDataGrid';

export const stColumns: any[] = [
  {
    field: 'val_tot_icms_st',
    headerName: 'Tot. ST',
    width: 130,
    format: 'number2',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_bc_st',
    headerName: 'BC ST',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_fcp_st',
    headerName: 'FCP ST',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  // {
  //   field: 'val_icms',
  //   headerName: 'val_icms',
  //   width: 150,
  //   sortable: false,
  //   disableColumnMenu: true,
  // },
  {
    field: 'val_fcp_icms',
    headerName: 'FCP ICMS',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
];
