import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

import { tableItensColumns } from './TableItens';

import { ScrollButtonsContainer } from './styles';

interface ScrollButtonProps extends ButtonProps {
  field: string;
}

const ScrollButton = ({ field, ...rest }: ScrollButtonProps) => {
  return <Button {...rest} onClick={() => scroll(field)} />;
};

const scroll = (field: string) => {
  const index = tableItensColumns.findIndex(
    (column: CustomGridColumns) => column.field === field,
  );
  const columns = tableItensColumns.slice(0, index);
  let offset = 0;
  columns.map((column: CustomGridColumns) => {
    offset += column.width || 0;
    return column;
  });
  if (offset === 0) {
    document.getElementsByClassName(
      'tableDevolucaoNFeContainer',
    )[0].scrollLeft = 0;
    return;
  }
  const tableElement = document.querySelector<HTMLElement>(
    '#tableDevolucaoESaida',
  );
  const tableWidth = tableElement?.offsetWidth || 0;
  if (offset >= tableWidth) {
    document.getElementsByClassName(
      'tableDevolucaoNFeContainer',
    )[0].scrollLeft = tableWidth;
    return;
  }
  document.getElementsByClassName('tableDevolucaoNFeContainer')[0].scrollLeft =
    offset;
};

export const ScrollButtons: React.FC = () => {
  return (
    <>
      <ScrollButtonsContainer>
        <ScrollButton field="num_item">Identificador</ScrollButton>
        <ScrollButton field="val_peso">Quantidade</ScrollButton>
        <ScrollButton field="val_tot_despesa_acessoria">Variáveis</ScrollButton>
        <ScrollButton field="val_tot_icms_st">ST</ScrollButton>
        <ScrollButton field="val_venda_varejo">Venda Varejo</ScrollButton>
        <ScrollButton field="val_bc_icms">ICMS</ScrollButton>
        <ScrollButton field="val_icms_ent_nf">Tributação Entrada</ScrollButton>
        <ScrollButton field="val_icms_sai_prod">Tributação Saída</ScrollButton>
      </ScrollButtonsContainer>
    </>
  );
};
