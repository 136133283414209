import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { icmsColumns } from './Icms';

import { identificadorColumns } from './Idenficador';
import { quantidadeColumns } from './Quantidade';
import { stColumns } from './St';
import { vendaVarejoColumns } from './VendaVarejo';
import { tributacaoEntradaColumns } from './TributacaoEntrada';
import { tributacaoSaidaColumns } from './TributacaoSaida';
import { variaveisColumns } from './Variaveis';

export const tableItensColumns: CustomGridColumns[] = [
  ...identificadorColumns,
  ...quantidadeColumns,
  ...variaveisColumns,
  ...stColumns,
  ...vendaVarejoColumns,
  ...icmsColumns,
  ...tributacaoEntradaColumns,
  ...tributacaoSaidaColumns,
];
