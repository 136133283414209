import React, { useCallback, useState } from 'react';

import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

import { tableItensColumns } from './TableItens';

import { TableContainer, Container, ButtonRow } from './styles';
import { ScrollButtons } from './ScrollButtons';
import CustomDataGrid from '../CustomDataGrid';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import { Produto } from '../../../../protocols';
import { MdDeleteForever } from 'react-icons/md';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from './components/Modal';
import { moneyFormat } from '~/utils/functions';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { procedureCalculaSubtotal } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';

const MySwal = withReactContent(Swal);

export const TableItens: React.FC = () => {
  const {
    produtos,
    setProdutos,
    setSubTotais,
    setProdutoSelecionado,
    subTotais,
    nfsReferenciadas,
    setNfsReferenciadas,
    isUpdate,
    checkIgnoraBaseDeCalculo,
    setResetItens,
    formTabelaItens,
  } = useDevolucaoSaidaNFEForn();
  const [showModal, setShowModal] = useState<boolean>(false);

  const { setValueTabelaItens } = formTabelaItens;

  const setTableInputsValues = useCallback(
    (produtosData: Produto[]) => {
      for (let i = 0; i < produtosData.length; i++) {
        const codSeqNFItem = produtosData[i].cod_seq_nf_item;
        const valTabela = `val_tabela_produto-${codSeqNFItem}`;
        const valTotDespesa = `val_tot_despesa_acessoria_produto-${codSeqNFItem}`;
        const valAcrecsimo = `val_acrescimo_produto-${codSeqNFItem}`;
        const valIndenizacao = `val_indenizacao_produto-${codSeqNFItem}`;
        const valTotFrete = `val_tot_frete_produto-${codSeqNFItem}`;
        const valTotDesconto = `val_tot_desconto_produto-${codSeqNFItem}`;

        setValueTabelaItens(
          valTabela,
          moneyFormat(String(produtosData[i].val_tabela || 0), 2),
        );
        setValueTabelaItens(
          valTotDespesa,
          moneyFormat(
            String(produtosData[i].val_tot_despesa_acessoria || 0),
            2,
          ),
        );
        setValueTabelaItens(
          valAcrecsimo,
          moneyFormat(String(produtosData[i].val_acrescimo || 0), 2),
        );
        setValueTabelaItens(
          valIndenizacao,
          moneyFormat(String(produtosData[i].val_indenizacao || 0), 2),
        );
        setValueTabelaItens(
          valTotFrete,
          moneyFormat(String(produtosData[i].val_tot_frete || 0), 2),
        );
        setValueTabelaItens(
          valTotDesconto,
          moneyFormat(String(produtosData[i].val_tot_desconto || 0), 2),
        );
      }
    },
    [setValueTabelaItens],
  );

  const handleRemoveItem = useCallback(
    async (cod_seq_nf_item: number, des_produto: string, num_nf: number) => {
      // Confirma remoção do item da tabela
      const confirmaRemocao = await MySwal.fire({
        title: 'Deseja remover o item?',
        text: des_produto,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (confirmaRemocao) {
        setResetItens(false);
        // Remove produto da lista
        let newProductList: Produto[] = [];

        setProdutos((prevProdutos) => {
          const prods = prevProdutos
            .filter((produto) => produto.cod_seq_nf_item !== cod_seq_nf_item)
            .map((produto, index) => ({ ...produto, num_item: index + 1 }));
          newProductList = prods;
          return prods;
        });
        // Recalcula subtotais
        const subtotais = procedureCalculaSubtotal(newProductList);

        // Remove produto da NF Referenciada e Verifica se ela ainda ficará referenciada
        const newNfsReferenciadas = nfsReferenciadas
          .map((obj) => {
            if (obj.num_nf === num_nf) {
              return {
                ...obj,
                itens: obj.itens.filter(
                  (item) => item !== String(cod_seq_nf_item),
                ),
              };
            }
            return obj;
          })
          .filter((obj) => obj.itens.length > 0);
        setNfsReferenciadas(newNfsReferenciadas);
        // Atualiza state de subtotais e produtos
        setSubTotais(subtotais);
        setTableInputsValues(newProductList);
        setResetItens(true);
      }
    },
    [
      nfsReferenciadas,
      setNfsReferenciadas,
      setProdutos,
      setResetItens,
      setSubTotais,
      setTableInputsValues,
    ],
  );

  const handleOpenModal = useCallback(
    (cod_seq_nf_item: number) => {
      const prod = produtos.find(
        (item) => item.cod_seq_nf_item === cod_seq_nf_item,
      );
      if (prod) {
        setProdutoSelecionado(prod);
        checkIgnoraBaseDeCalculo(!prod.flg_nao_calc_bc_icms);
        setShowModal(true);
      }
    },
    [checkIgnoraBaseDeCalculo, produtos, setProdutoSelecionado],
  );

  const actions: CustomGridColumns[] = [
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'right',
      width: 200,
      renderCell: (row: any) => {
        const product = produtos.find(
          (prod) => prod.cod_produto === row.cod_produto,
        );

        let { flg_nao_calc_bc_icms } = row;

        if (product) {
          if (isUpdate) {
            flg_nao_calc_bc_icms = product.flg_nao_calc_bc_icms;
          }
        }

        return (
          <div>
            {/* <ButtonRow
              type="button"
              disabled={isUpdate}
              onClick={() => {
                setProdutoSelecionado(row);
                setShowModal(true);
              }}
              style={{ marginRight: '10px' }}
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow> */}
            <ButtonRow
              type="button"
              disabled={isUpdate}
              onClick={() => {
                handleOpenModal(row.cod_seq_nf_item);
              }}
            >
              <Form.Check
                style={{ margin: 'auto' }}
                type="checkbox"
                disabled={isUpdate}
                label="Ignora BC?"
                checked={flg_nao_calc_bc_icms}
                size={5}
              />
            </ButtonRow>
            <ButtonRow
              type="button"
              disabled={isUpdate}
              onClick={() => {
                handleRemoveItem(
                  row.cod_seq_nf_item,
                  row.des_produto,
                  row.num_nf,
                  // row.cod_produto,
                  // row.num_item,
                );
              }}
            >
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </div>
        );
      },
    },
  ];

  const columns: CustomGridColumns[] = [...tableItensColumns, ...actions];

  return (
    <Container>
      <Row>
        <Col md={12} sm={12}>
          <ScrollButtons />
          <TableContainer>
            <CustomDataGrid loading={false} rows={produtos} columns={columns} />
          </TableContainer>
        </Col>
        {showModal && (
          <Modal
            handleModal={{
              showModal,
              setShowModal,
            }}
          />
        )}

        <Col md={12} sm={12}>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  Total Produtos:{' '}
                  {moneyFormat(String(subTotais.totalProdutos), 2)}
                </td>
                <td>ST (NF): {moneyFormat(String(subTotais.totalST), 2)}</td>
                <td>Frete (NF): {moneyFormat(String(subTotais.frete), 2)}</td>
              </tr>
              <tr>
                <td>
                  BC ICMS OP: {moneyFormat(String(subTotais.bcIcmsOp), 2)}
                </td>
                <td>(Guia) ST: {moneyFormat(String(subTotais.guiaSt), 2)}</td>
                <td>Val. IPI: {moneyFormat(String(subTotais.ipi), 2)}</td>
              </tr>
              <tr>
                <td>ICMS OP: {moneyFormat(String(subTotais.icmsOp), 2)}</td>
                <td>FCP ST: {moneyFormat(String(subTotais.fcpSt), 2)}</td>
                <td>
                  Total da NF: {moneyFormat(String(subTotais.totalNf), 2)}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};
