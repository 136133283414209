import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { InputMoney } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import {
  CalculaCamposCalculados,
  DefinirTributacao,
  Subtotais,
  procedureCalculaSubtotal,
} from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import { getLojaUf } from '~/pages/DevolucaoESaidaNFDeFornecedor/services';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { schema } from '../../../../../../validations/FormEditProductValidation';
import { BootstrapModal } from './styles';

interface ModalProps {
  handleModal: {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { handleModal } = props;
  const {
    getValues: getSaidaValues,
    produtoSelecionado,
    setProdutoSelecionado,
    setProdutos,
    produtos,
    setSubTotais,
    parametrosLoja,
  } = useDevolucaoSaidaNFEForn();
  const [backdrop, setBackdrop] = useState(true);
  const [startStatus] = useState(produtoSelecionado.flg_nao_calc_bc_icms);

  const { register, handleSubmit, setValue, clearErrors, formState } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const onSave = handleSubmit(async (data) => {
    setBackdrop(false);
    /**
     * Caso não haja alteração
     */
    if (
      startStatus === false &&
      produtoSelecionado.flg_nao_calc_bc_icms === false
    ) {
      toast.success('Nenhuma alteração efetuada.');
    }
    /**
     * Caso os valores sejam inseridos manualmente
     */
    if (
      (startStatus === false &&
        produtoSelecionado.flg_nao_calc_bc_icms === true) ||
      (startStatus === true && produtoSelecionado.flg_nao_calc_bc_icms === true)
    ) {
      if (data.val_outros_icms === '') data.val_outros_icms = '0,00';

      setProdutos((prev) =>
        prev.map((item) => {
          if (item.cod_seq_nf_item === produtoSelecionado.cod_seq_nf_item) {
            item.val_bc_icms = transformAsCurrency(data.val_bc_icms);
            item.val_icms = transformAsCurrency(data.val_icms);
            item.val_isento_icms = transformAsCurrency(data.val_isento);
            item.val_outros_icms = transformAsCurrency(data.val_outros_icms);
            item.flg_nao_calc_bc_icms = true;
          }
          return item;
        }),
      );

      const subtotais = procedureCalculaSubtotal(produtos);
      const sanitizedSubtotais: Subtotais = { ...subtotais };

      Object.keys(sanitizedSubtotais).forEach((key) => {
        const typedKey = key as keyof Subtotais;
        if (Number.isNaN(sanitizedSubtotais[typedKey]))
          sanitizedSubtotais[typedKey] = 0;
        setSubTotais(sanitizedSubtotais);
      });

      toast.success('Alterações efetuadas com sucesso.');
    }
    /**
     * Caso seja necessário recalcular o item
     */
    if (
      startStatus === true &&
      produtoSelecionado.flg_nao_calc_bc_icms === false
    ) {
      // recalcular
      const codLoja = getSaidaValues('loja');
      const ufLoja = await getLojaUf(codLoja);
      const fornecedor = getSaidaValues('cod_pessoa');
      const perfil = getSaidaValues('cod_perfil');

      const isOperacaoInterestadual = ufLoja !== fornecedor.des_uf;
      const VTotLiqItensSubst = 0;
      const VTotFimItensICMSA = 0;
      const QTotICMSAntecItens = 0;
      const pICMSRetido = 0;
      const parametroLoja = {
        flg_ignora_st_cf_custo: parametrosLoja.flg_ignora_st_cf_custo,
      };
      const produto = await DefinirTributacao(
        produtoSelecionado,
        ufLoja,
        fornecedor.des_uf,
      );
      if (produto) {
        const itemAtualizado = CalculaCamposCalculados(
          isOperacaoInterestadual,
          VTotLiqItensSubst,
          VTotFimItensICMSA,
          QTotICMSAntecItens,
          pICMSRetido,
          produto,
          0,
          fornecedor,
          perfil,
          parametroLoja,
          { per_desp_op: produtoSelecionado.per_desp_op },
        );
        setProdutos((prev) =>
          prev.map((item) => {
            if (item.cod_seq_nf_item === produtoSelecionado.cod_seq_nf_item) {
              itemAtualizado.flg_nao_calc_bc_icms = false;
              return itemAtualizado;
            }
            return item;
          }),
        );
        toast.success('Item recalculado com sucesso.');
      }
    }
    handleModal.setShowModal(false);
    setBackdrop(true);
  });

  useEffect(() => {
    setValue(
      'val_bc_icms',
      formatCurrencyAsText(produtoSelecionado.val_bc_icms),
    );
    setValue('val_icms', formatCurrencyAsText(produtoSelecionado.val_icms));
    setValue(
      'val_isento',
      formatCurrencyAsText(produtoSelecionado.val_isento_icms),
    );
    setValue(
      'val_outros_icms',
      formatCurrencyAsText(produtoSelecionado.val_outros_icms),
    );
  }, [
    produtoSelecionado.val_bc_icms,
    produtoSelecionado.val_icms,
    produtoSelecionado.val_isento_icms,
    produtoSelecionado.val_outros_icms,
    setValue,
  ]);

  const handleFlagIgnoraBaseDeCalculo = useCallback(
    (flg_nao_calc_bc_icms: boolean) => {
      const produto = {
        ...produtoSelecionado,
        flg_nao_calc_bc_icms,
      };
      setValue('flg_ignora_bc', flg_nao_calc_bc_icms);

      setProdutoSelecionado(produto);
      if (flg_nao_calc_bc_icms === false) {
        clearErrors();
      }
    },
    [clearErrors, produtoSelecionado, setProdutoSelecionado, setValue],
  );

  return (
    <>
      <BootstrapModal
        show={handleModal.showModal}
        onHide={() => handleModal.setShowModal(false)}
        centered
        size="lg"
        backdrop={backdrop || 'static'}
      >
        <BootstrapModal.Header>
          <BootstrapModal.Title>
            {produtoSelecionado.des_produto}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <Row>
            <Separator labelText="Quantidade/Valores" sidePadding="0 0" />
            <table className="table">
              <thead>
                <tr>
                  <th>Unidade</th>
                  <th>Qtd Embalagem</th>
                  <th>Qtd Saída</th>
                  <th>Valor Tabela</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {formatCurrencyAsText(produtoSelecionado.des_unidade)}
                  </td>
                  <td>
                    {formatCurrencyAsText(produtoSelecionado.qtd_embalagem)}
                  </td>
                  <td>
                    {formatCurrencyAsText(produtoSelecionado.qtd_devolucao)}
                  </td>
                  <td>
                    {formatCurrencyAsText(produtoSelecionado.val_tabela || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>Desp Acessória</th>
                  <th>Acréscimo</th>
                  <th>Indenização</th>
                  <th>Frete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {formatCurrencyAsText(
                      produtoSelecionado.val_tot_despesa_acessoria || 0,
                    )}
                  </td>
                  <td>0</td>
                  <td>
                    {formatCurrencyAsText(
                      produtoSelecionado.val_indenizacao || 0,
                    )}
                  </td>
                  <td>
                    {formatCurrencyAsText(
                      produtoSelecionado.val_tot_frete || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>IPI</th>
                  <th>Total ST</th>
                  <th>Venda Varejo</th>
                  <th>Total Tabela</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {formatCurrencyAsText(produtoSelecionado.val_tot_ipi || 0)}
                  </td>
                  <td>
                    {formatCurrencyAsText(
                      produtoSelecionado.val_tot_icms_st || 0,
                    )}
                  </td>
                  <td>
                    {formatCurrencyAsText(
                      produtoSelecionado.val_venda_varejo || 0,
                    )}
                  </td>
                  <td>
                    {formatCurrencyAsText(
                      produtoSelecionado.val_tabela_final || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row>
            <Separator labelText="" childrenWidth="110px">
              <div
                style={{
                  marginTop: '-5px',
                }}
              >
                <ToggleDefault
                  labelText="Ignora BC?"
                  setChecked={produtoSelecionado.flg_nao_calc_bc_icms}
                  onSwitch={() =>
                    handleFlagIgnoraBaseDeCalculo(
                      !produtoSelecionado.flg_nao_calc_bc_icms,
                    )
                  }
                  disabled={!backdrop}
                  inLine
                  keepInLine
                />
              </div>
            </Separator>
            <Col sm={12} md={3}>
              <InputMoney
                label="BC ICMS"
                min={0}
                maxLength={50}
                placeholder="0"
                name="val_bc_icms"
                register={register}
                disabled={!produtoSelecionado.flg_nao_calc_bc_icms || !backdrop}
                isError={!!formState.errors.val_bc_icms}
              />
            </Col>
            <Col sm={12} md={3}>
              <InputMoney
                label="ICMS OP"
                min={0}
                maxLength={50}
                placeholder="0"
                name="val_icms"
                register={register}
                disabled={!produtoSelecionado.flg_nao_calc_bc_icms || !backdrop}
                isError={!!formState.errors.val_icms}
              />
            </Col>
            <Col sm={12} md={3}>
              <InputMoney
                label="Isento"
                min={0}
                maxLength={50}
                placeholder="0"
                name="val_isento"
                register={register}
                disabled={!produtoSelecionado.flg_nao_calc_bc_icms || !backdrop}
                isError={!!formState.errors.val_isento}
              />
            </Col>
            <Col sm={12} md={3}>
              <InputMoney
                label="Outros"
                min={0}
                maxLength={50}
                placeholder="0,00"
                name="val_outros_icms"
                register={register}
                disabled={!produtoSelecionado.flg_nao_calc_bc_icms || !backdrop}
                isError={!!formState.errors.val_outros_icms}
              />
            </Col>
          </Row>
        </BootstrapModal.Body>
        <BootstrapModal.Footer
          style={{
            marginTop: '60px',
            paddingRight: '75px',
          }}
        >
          {!backdrop && (
            <>
              <CircularProgress size={20} style={{ color: '#46057e' }} />
              <h5>Recalculando...</h5>
            </>
          )}

          <Button
            variant="secondary"
            onClick={() => {
              onSave();
            }}
          >
            Salvar
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};
