import React from 'react';
import { CustomGridColumns } from '../../CustomDataGrid';

export const icmsColumns: CustomGridColumns[] = [
  {
    field: 'val_bc_icms',
    headerName: 'BC ICMS OP',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_icms',
    headerName: 'ICMS OP',
    width: 100,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_isento_icms',
    headerName: 'Tot. Isento',
    width: 110,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_outros_icms',
    headerName: 'Tot. Outros',
    width: 110,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
];
