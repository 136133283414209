import React from 'react';
import { moneyFormat, transformAsCurrency } from '~/utils/functions';
import { CustomGridColumns } from '../../CustomDataGrid';
import CustomInputMoney from './components/CustomInputMoney';

export const quantidadeColumns = [
  {
    field: 'val_peso',
    headerName: 'Peso',
    width: 100,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'des_unidade',
    headerName: 'UN',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_embalagem',
    headerName: 'Qtd. Embalagem',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_devolucao',
    headerName: 'Qtd. Saída',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_total',
    headerName: 'Qtd. Total',
    width: 130,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_tabela',
    headerName: 'Valor Tabela',
    width: 130,
    sortable: false,
    format: 'number6',
    disableColumnMenu: true,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      const fieldName = `val_tabela_produto-${row.cod_seq_nf_item}`;

      if (row.isupdate) {
        return <>{moneyFormat(String(row.val_tabela || 0), 2)}</>;
      }
      return (
        <CustomInputMoney row={row} fieldName={fieldName} campo="val_tabela" />
      );
    },
  },
  {
    field: 'val_total',
    headerName: 'Total Tabela',
    width: 130,
    format: 'number2',
    sortable: false,
    disableColumnMenu: true,
  },
];
